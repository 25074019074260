import Navbar from "../components/NavbarUsers";
import { StyledSubtitle, colors } from "../components/Styles";
import FaltasTabla from "../components/incidencias/TablaFaltas";
import IncidentForm from "../components/incidencias/incidenciasFecha";
import GuardiasFindeSemana from "../components/incidencias/GuardiasFindeSemana";

const IncidenciasPage = () => {
  return (
    <div>
      <Navbar />
      <div className="container">
        <StyledSubtitle color={colors.theme} size={24}>
          Carga de Incidencias por dia
        </StyledSubtitle>
        <div>
          <FaltasTabla />
        </div>
        <StyledSubtitle color={colors.theme} size={24}>
          Crear Incidencia por fecha
        </StyledSubtitle>
        <div>
          <IncidentForm />
        </div>
        <StyledSubtitle color={colors.theme} size={24}>
          Carga de Incidencias en Excel
        </StyledSubtitle>
        <div>
          <GuardiasFindeSemana />
        </div>
      </div>
    </div>
  );
};

export default IncidenciasPage;