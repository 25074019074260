import React, { useState, useEffect, useRef } from 'react';
import 'tabulator-tables/dist/css/tabulator.min.css';
import 'flatpickr/dist/flatpickr.min.css';
import Flatpickr from 'flatpickr';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import * as XLSX from 'xlsx';

//import CharacterCount from './CuntPrenomina';

import { useGetClientId } from "../../hooks/useGetUserClientId";
import { StyledExcelButton, StyledFormButton, ButtonGroup } from '../Styles';


const PrenominaR = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [table, setTable] = useState(null);
  const [loading, setLoading] = useState(false);

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const CLIENTID = useGetClientId();

  useEffect(() => {
    Flatpickr(startDateRef.current, {
      dateFormat: 'Y-m-d',
      onChange: ([date]) => setStartDate(date.toISOString().split('T')[0]),
    });
    Flatpickr(endDateRef.current, {
      dateFormat: 'Y-m-d',
      onChange: ([date]) => setEndDate(date.toISOString().split('T')[0]),
    });
  }, []);

  useEffect(() => {
    if (table) {
      table.redraw(true); // Redraw the table to ensure layout is updated
    }
  }, [table]);

  const handleFetchData = async () => {
    setLoading(true);
    const { transformedData, columns } = await fetchData(startDate, endDate);
    if (table) {
      table.setData(transformedData);
      table.setColumns(columns);
    } else {
      const newTable = new Tabulator('#data-table', {
        data: transformedData,
        layout: 'fitDataStretch',
        columns: columns,
        renderHorizontal: "virtual",
        resizableColumnFit: false,
        pagination: "local", // Enable local pagination
        paginationSize: 50, // Number of rows per page
        cellVertAlign: 'middle',
        cellHozAlign: 'center',
      });
      setTable(newTable);
    }
    setLoading(false);
  };

  const handleDownload = async () => {
    setLoading(true);
    const { transformedData, columns } = await fetchData(startDate, endDate);
    const downloadableData = transformedData.map(row => {
      const newRow = {};
      columns.forEach(col => {
        newRow[col.title] = row[col.field];
      });
      return newRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(downloadableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const dataUri = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });
    const link = document.createElement('a');
    const downloadName = `${transformedData[0]?.contract_name || 'data'}_${startDate}-${endDate}`;
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${dataUri}`;
    link.download = `${downloadName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false);
  };

  const fetchData = async (startDate, endDate) => {
    const baseUrl = 'https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/asistencias/prenom/';
    const url = `${baseUrl}${startDate}/${endDate}/${CLIENTID}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return transformData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      return { transformedData: [], columns: [] };
    }
  };

  const transformData = (data) => {
    const pivotData = {};
    const dates = new Set();
    let countMap = {};
  
    data.forEach((item) => {
      if (!pivotData[item.nombre]) {
        pivotData[item.nombre] = {
          nombre: item.nombre,
          contract_name: item.contract_name,
          Site: item.e_attribute4,
          'ID Nominas': item.employee_number,
          PUESTO: item.e_attribute3,
          TURNO: determineTurno(item.horas),
          fechadeinicio: item.fechadeinicio || '',
          fechabaja: item.fechabaja || '-',
          horario: item.horas || '',
          jornada: calculateJornada(item.horas),
        };
      }
  
      let horasValue = item.hora;
      if (horasValue === 'F') {
        horasValue = 'FI';
      } else if (horasValue === 'Dom' || horasValue === 'Sab') {
        horasValue = 'D';
      } else if (/[\d]/.test(horasValue)) {
        horasValue = item.retardo === "Y" ? 'R' : 'A';
      }
      pivotData[item.nombre][item.fecha] = horasValue;
      dates.add(item.fecha);
  
      if (['FI', 'B', 'INC', 'AA', 'SUS', 'PS/G', 'FJ', 'AUS', ''].includes(horasValue)) {
        if (!countMap[item.nombre]) {
          countMap[item.nombre] = 0;
        }
        countMap[item.nombre]++;
      }
    });
  
    const filteredData = Object.values(pivotData).filter(item => {
      const horasValuesInRange = Array.from(dates).map(date => item[date]).filter(Boolean);
      return !horasValuesInRange.every(value => value === 'B');
    });
  
    const transformedData = filteredData.map((item, index) => {
      const totalDays = 15; // Assuming 15 is the total number of days for calculation
  
      // Calculate the Last_Column value
      const count = countMap[item.nombre] || 0;
      const lastColumnValue = totalDays - count;
  
      return {
        consecutiveNumber: index + 1,
        ...item,
        Last_Column: Math.max(0, lastColumnValue), // Ensure the value is not less than zero
      };
    });
  
    const columns = [
      { title: '#', field: 'consecutiveNumber', frozen: true, width: 50 },
      { title: 'Contract Name', field: 'contract_name', frozen: true, width: 90 },
      { title: 'Site', field: 'Site', frozen: true, width: 90 },
      { title: 'ID Nominas', field: 'ID Nominas', frozen: true, width: 90,
        headerFilter: "input",
        headerFilterParams: {
          placeholder: "Search...",
          minSearchChars: 2,
        },
        headerFilterPlaceholder: "Buscar...",
      },
      { title: 'PUESTO', field: 'PUESTO', frozen: true, width: 90 },
      { title: 'Nombre', field: 'nombre',
        headerFilter: "input",
        headerFilterParams: {
          minSearchChars: 2,
        },
        headerFilterFunc: (headerValue, rowValue) => rowValue.toLowerCase().includes(headerValue.toLowerCase()),
      },
      { title: 'TURNO', field: 'TURNO', width: 90 },
      { title: 'Fecha de Ingreso', field: 'fechadeinicio', width: 90 },
      { title: 'Fecha de Baja', field: 'fechabaja', width: 90 },
      { title: 'Jornada', field: 'jornada', width: 90 },
      { title: 'Horario', field: 'horario', width: 90 }
    ];
  
    dates.forEach(date => {
      columns.push({ title: date, field: date, headerVertical: true, align: 'center' });
    });
  
    columns.push({ title: 'Dias Trabajados', field: 'Last_Column', hozAlign: "center" });
  
    return { transformedData, columns };
  };
  const calculateJornada = (horas) => {
    if (!horas || typeof horas !== 'string') return '';

    const [start, end] = horas.split('-');
    if (!start || !end) return '';

    const [startHour, startMinute] = start.split(':').map(Number);
    const [endHour, endMinute] = end.split(':').map(Number);

    const startTime = new Date(0, 0, 0, startHour, startMinute, 0);
    const endTime = new Date(0, 0, 0, endHour, endMinute, 0);

    let diff = (endTime - startTime) / (1000 * 60 * 60); // Difference in hours

    if (diff < 0) {
      diff += 24; // Adjust for overnight shifts
    }

    return diff.toFixed(2);
  };

  const determineTurno = (horas) => {
    if (!horas || typeof horas !== 'string') return 'Desconocido';
  
    // Split the `horas` field to get the first part
    const [startTime] = horas.split('-'); // Get the start time part
  
    // Extract hours and minutes from the start time
    const [hour, minute] = startTime.split(':').map(Number);
  
    // Check if the hour is less than 13
    if (hour < 13) {
      return 'Matutino';
    } else {
      return 'Vespertino'; // Return 'Desconocido' if not 'Matutino'
    }
  };

  return (
    <div>
     
      <div className="row">
        <div className="col s3">
          <label htmlFor="start-date">Fecha de Inicio:</label>
          <input type="text" id="start-date" ref={startDateRef} />
        </div>
        <div className="col s3">
          <label htmlFor="end-date">Fecha Final</label>
          <input type="text" id="end-date" ref={endDateRef} />
        </div>
        <ButtonGroup>
        <StyledFormButton onClick={handleFetchData} disabled={loading}>
          Generar
        </StyledFormButton>
        <StyledExcelButton onClick={handleDownload} disabled={loading}>
          Descargar Excel
        </StyledExcelButton>
        </ButtonGroup>
      </div>
      {loading && (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      )}
      <div id="table-container">
        <div id="data-table" className="tabulator"></div>
      </div>
      <div id="table-placeholder" style={{ display: table ? 'none' : 'block' }}>
        
      </div>
    </div>
  );
};

export default PrenominaR;
