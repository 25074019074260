

import CalendarioF from "../components/calendario/calendarioFinal";
import Navbar from "../components/NavbarUsers";
import { StyledSubtitle, colors } from '../components/Styles';

const CalendarioPage = () => {
    return(
        <div>
            <Navbar/>  
            <StyledSubtitle color={colors.theme} size={24}>
                Calendario Por Fechas
            </StyledSubtitle>
            <CalendarioF/>
        </div>
    )
}

export default CalendarioPage