import React, { useEffect, useState } from "react";
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import "materialize-css/dist/css/materialize.min.css";
import M from 'materialize-css/dist/js/materialize.min.js';
import { DateTime } from 'luxon';
import { useGetClientId } from "../../hooks/useGetUserClientId";
import * as XLSX from 'xlsx';
import "../tabulator-custom.css";
import { StyledExcelButton } from "../Styles";

const FaltasTabla = () => {
  const CLIENTID = useGetClientId();
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(() => DateTime.now().toFormat('yyyy-MM-dd'));
  const [table, setTable] = useState(null);
  
  const URL_POST = "https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/incidencias";

  const fetchData = async () => {
    const URL_GET = `https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/asistencias/prenom/${selectedDate}/${selectedDate}/${CLIENTID}`;
    
    try {
      const response = await fetch(URL_GET);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      return await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
      M.toast({ html: `Error fetching data: ${error.message}`, classes: "custom-toast" });
      return [];
    }
  };

  const initializeTable = (data) => {
    if (table) {
      table.destroy();  // Clean up existing table if any
    }

    const newTable = new Tabulator("#incidents-table", {
      data,
      layout: "fitColumns", // Auto-adjust columns to fit content
      responsiveLayout: false, // Disable responsive layout
      resizableColumns: true, // Enable responsive layout
      pagination: "remote",  // Enable pagination for large datasets
      paginationSize: 50,  
      columns: [
        { title: "#", field: "rownum", formatter: "rownum", width: 30 },
        {
          title: "EmpID",
          field: "employee_number",
          width: "10px",
          headerFilter: "input",
          headerFilterParams: {
            placeholder: "Search...",
            minSearchChars: 2,
          },
          headerFilterPlaceholder: "Buscar...",
        },
        {
          title: "Nombre",
          field: "nombre",
          headerFilter: "input",
          headerFilterParams: {
            minSearchChars: 2,
          },
          headerFilterFunc: (headerValue, rowValue) => rowValue.toLowerCase().includes(headerValue.toLowerCase()),
        width: "30px"},
        {
          title: "Fecha Baja",
          field: "fechabaja",
          width: "11px",
          editorParams: {
            format: "yyyy-MM-dd",
            verticalNavigation: "table",
          },
        },
        { title: "contrato", field: "contract_name",width: "12px" },
        { title: "Site",
           field: "e_attribute4",
           width: "11px",
           topCalc:"count",
           editor: "list",
           headerFilter: true,
           headerFilterParams: { values: { XOLA: "XOLA", "HOME OFFICE": "HOME OFFICE", "AÑIL":"AÑIL", "TRONCOSO": "TRONCOSO", "":"TODOS" }, clearable: true }, },
        { title: "Puesto", field: "e_attribute3", width: "11px" },
        {
            title: "Incidencia",
            field: "hora",
            editor: "list",
            hozAlign: "center",
            width: "13px",
            topCalc:"count",
            editorParams: { values: { F : "Faltas Injustificadas", INC: "Incapacidad", V : "Vacaciones", D : "Descanso", FJ : "Faltas Justificadas", HO : "Home Office", PH : "Pago Horas", "PS/G" : "Permiso SIN Goce", "PC/G" : "Permiso CON Goce", C : "Curva", CAP : "Capacitación", AA : "Acta Administrativa" } },
            headerFilter: true,
        headerFilterParams: { values: { "F" : "Faltas Injustificadas", "INC" : "Incapacidad", "V" : "Vacaciones", "D" : "Descanso", "FJ" : "Faltas Justificadas", "HO" : "Home Office", "PH" : "Pago Horas", "PS/G" : "Permiso SIN Goce", "PC/G" : "Permiso CON Goce", "C" : "Curva", "CAP" : "Capacitación", "AA" : "Acta Administrativa", "B" : "Baja", "" : "" }, clearable: true },
          },
      ],
    });

    setTable(newTable);

    newTable.on("cellEdited", async function (cell) {
      const updatedRow = cell.getRow().getData();
      const fieldName = cell.getField();
      const updatedValue = cell.getValue();

      const payload = {
        numempleado: updatedRow.employee_number,
        nombre: updatedRow.nombre,
        client_id: CLIENTID,
        fechaDeInicio: selectedDate,
        fechaDeFin: selectedDate,
        tipoIncidencia: updatedRow.hora,
      };

      console.log("Payload to be sent:", payload);

      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          mode: "no-cors",
          body: JSON.stringify(payload),
        };

        await fetch(URL_POST, options);
        console.log("Data sent successfully");
        M.toast({
          html: `Campo Actualizado: ${fieldName}\n  ${updatedValue}\nActualizado Correctamente!`,
          classes: "green",
        });
      } catch (error) {
        console.error("Error saving data:", error);
        M.toast({
          html: `Failed to update field: ${fieldName}\nError: ${error.message}`,
        });
      }
    });
  };

  const handleDownload = async () => {
    if (!table) {
      console.error("Table instance not found");
      return;
    }
  
    try {
      const data = await table.getData(); // Await if it's a promise
  
      // Get column definitions for headers
      const columns = table.getColumnDefinitions();
      const headers = columns.map(col => col.title);
      
      // Prepare the data for XLSX
      const wsData = [
        headers, // Add headers
        ...data.map(row => columns.map(col => row[col.field] || '')) // Add rows
      ];
  
      // Create a new workbook and worksheet
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${selectedDate}`);
  
      // Generate Excel file
      const fileName = `Incidencias del día - ${selectedDate}.xlsx`; // Use selectedDate for filename
      XLSX.writeFile(wb, fileName);
    } catch (error) {
      console.error("Error generating XLSX file:", error);
      M.toast({ html: `Error downloading file: ${error.message}`, classes: "custom-toast" });
    }
  };

  useEffect(() => {
    const loadTable = async () => {
      setLoading(true);
      const data = await fetchData(); // Fetch data based on the selected date
      initializeTable(data);
      setLoading(false);
    };

    loadTable();
  }, [selectedDate]);

  return (
    <>
      {loading && (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      )}
      <div>
          <div className="input-field row">
              <div className="col s3">
                <input
                  type="date"
                  id="date-picker"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </div>

              <div className="col s3">
                <StyledExcelButton onClick={handleDownload}>
                  Descargar Excel
                </StyledExcelButton>
              </div>
          </div>


        <div id="incidents-table" className="tabulator"></div>
      </div>
    </>
  );
};

export default FaltasTabla;
