import PrenominaR from "../components/formatos/FormatoPrenomina";
import Navbar from "../components/NavbarUsers";
import { StyledSubtitle, colors } from "../components/Styles";


const FormatPage = () => {

    return(
        <div>
            <Navbar />
            <div className="container">
            <StyledSubtitle color={colors.theme} size={24}>
                Formato de Prenómina
            </StyledSubtitle>
            <PrenominaR />
            </div>
        </div>
    )
};

export default FormatPage