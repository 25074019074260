import { 
    StyledTitle, 
    StyledFormButton,
    Avatar , 
    StyledFormArea,
    colors, 
    ButtonGroup,
    StyledContainer
    } from '../components/Styles';
import Logo from "./../assets/liccoLogo.png"





const Login = () => {


    return(
        <div>
            <StyledContainer>
            <StyledFormArea>
            <Avatar image={Logo}/>
            <StyledTitle color={colors.theme} size={24}>

                Iniciar Sesión

            </StyledTitle>
                        <ButtonGroup>
                        </ButtonGroup>  
            </StyledFormArea>
            </StyledContainer>
       
        </div>
    )
}

export default Login