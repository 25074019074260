

import { StyledTitle, StyledSubtitle, Avatar, StyledButton, ButtonGroup, StyledContainer } from '../components/Styles';

import Logo from "../assets/liccoLogo.png"





const HomePage = () => {
    return(
        <div>

        <StyledContainer>
            
        <div>
        <Avatar $image={Logo}/>
        </div>

            <StyledTitle>
                Liccontrol de Asistencia
            </StyledTitle>
            <StyledSubtitle>
                Inicia sesión para continuar
            </StyledSubtitle>

            <ButtonGroup>
            <StyledButton to="/auth">Entrar</StyledButton>
            
            </ButtonGroup>

        </StyledContainer>

    
      
       
    </div>
        
    )
}

export default HomePage