import React, { useState, useEffect, useRef } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css';
import { StyledFormButton } from '../Styles'; // Adjust the import path as necessary
import { useGetClientId } from "../../hooks/useGetUserClientId";
import { read, utils } from 'xlsx';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import 'tabulator-tables/dist/css/tabulator.min.css';
import { TabulatorFull as Tabulator } from 'tabulator-tables';

function GuardiasFindeSemana() {
  const CLIENTID = useGetClientId();
  const { register, handleSubmit } = useForm();
  const [fileContent, setFileContent] = useState(null);
  const tableRef = useRef(null);
  //const isTrue = true; // Define the isTrue variable

  const toJsonv4 = (data) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(data.foto[0]);
  
    reader.onload = (event) => {
      const dataa = new Uint8Array(event.target.result);
      const libro = read(dataa, { type: "array" });
      const nombreHoja = libro.SheetNames[0];
      const hoja = libro.Sheets[nombreHoja];
      const dataJson = utils.sheet_to_json(hoja, { header: 1, raw: false, dateNF: 'yyyy-mm-dd' });

      // Ignore the first row (general title)
      const headers = dataJson[1]; // Second row as headers
      const client = CLIENTID;
      const incidencias = [];

      for (let i = 2; i < dataJson.length; i++) {
        const row = dataJson[i];
        const numEmpleado = row[headers.indexOf('Numero de empleado')];
        const nombreCompleto = row[headers.indexOf('NOMBRE COMPLETO')];
        const tipoIncidencia = 'GUARDIA'; // Assuming 'G' is the type of incidence

        // Process date columns
        const dateColumns = headers.slice(4); // Skip first four columns
        dateColumns.forEach((dateHeader, index) => {
          const dateValue = row[headers.indexOf(dateHeader)];
          if (dateValue) {
            const incidencia = {
              client_id: client,
              numempleado: numEmpleado.toString(),
              nombre: nombreCompleto,
              fechaDeInicio: dateHeader,
              fechaDeFin: dateHeader,
              tipoIncidencia: tipoIncidencia
            };
            incidencias.push(incidencia);
          }
        });
      }

      const payload = { incidencias };
      console.log("Generated JSON Payload:", payload); // Temporary console output

      // Store file content in the state
      setFileContent({ headers, data: dataJson.slice(2) }); // Exclude the first row (general title)

      M.toast({ html: 'Enviando incidencias...', classes: 'green' });
      incidenciasMasivas(payload);
    }
  }

  const incidenciasMasivas = async (jsonObject) => {
    const url = "https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/incidencias/masivas";

    const opciones = jsonObject;

    await axios.post(url, opciones)
      .then((response) => {
        console.log(response);
        if (response.data.error_code === 1) {
          M.toast({ html: 'El formato de los parametros no es correcto o se recibio vacío.', classes: 'red' });
        } else {
          M.toast({ html: 'Incidencias cargadas correctamente.', classes: 'green' });
        }
      }).catch(error => {
        M.toast({ html: 'Error al enviar la incidencia.', classes: 'red' });
        console.log(error);
      });
  }

  useEffect(() => {
    M.AutoInit(); // Initialize all Materialize components
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = (event) => {
        const dataa = new Uint8Array(event.target.result);
        const libro = read(dataa, { type: "array" });
        const nombreHoja = libro.SheetNames[0];
        const hoja = libro.Sheets[nombreHoja];
        const dataJson = utils.sheet_to_json(hoja, { header: 1, raw: false, dateNF: 'yyyy-mm-dd' });

        // Ignore the first row (general title)
        const headers = dataJson[1]; // Second row as headers
        const data = dataJson.slice(2).map(row => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = row[index];
          });
          return obj;
        }).filter(row => Object.values(row).some(value => value !== undefined && value !== null && value !== ''));

        setFileContent({ headers, data });
      }
    }
  };

  useEffect(() => {
    if (fileContent && tableRef.current) {
      const { headers, data } = fileContent;
      const columns = headers.map(key => ({ title: key, field: key.toString() }));
      new Tabulator(tableRef.current, {
        data,
        layout: "fitColumns",
        columns,
      });
    }
  }, [fileContent]);

  return (
    <div>
      <div>
       
        <form onSubmit={handleSubmit(toJsonv4)}>
          <input type='file' className="browser-default" {...register('foto', { required: true })} onChange={handleFileChange} />
          <StyledFormButton type='submit'>Enviar</StyledFormButton>
        </form>
      </div>

      <div>
        <p color="orangered" size="24" className="sc-egkSDF fXquAz">Vista previa del archivo cargado</p>
        {fileContent && (
          <>
            <h5>GUARDIAS FIN DE SEMANA</h5>
            <div ref={tableRef} className="tabulator"></div>
          </>
        )}
      </div>
    </div>
  );
}

export default GuardiasFindeSemana;