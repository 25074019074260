import React, { useEffect, useRef, useState } from 'react';
import { Tabulator } from 'tabulator-tables';
import 'tabulator-tables/dist/css/tabulator.min.css'; // Import Tabulator CSS
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { useGetClientId } from "../../hooks/useGetUserClientId";

// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, ArcElement);

// Function to get the first day of the current month and today's date in local timezone
const getTodayBounds = () => {
  const now = new Date();
  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
  const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return { 
    startOfMonth: formatDate(startOfDay), 
    endOfMonth: formatDate(endOfDay) 
  };
};

const CountTablaHoy = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [chartData, setChartData] = useState({});
  const tableRef = useRef(null);
  const CLIENTID = useGetClientId();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Show loading spinner

        const { startOfMonth, endOfMonth } = getTodayBounds();
        const URL = `https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/asistencias/prenom/${startOfMonth}/${endOfMonth}/${CLIENTID}`;

        const response = await fetch(URL);
        const result = await response.json();

        // Count occurrences of specific values in the 'horas' field
        const counts = {
          F: 0,
          R: 0,
          V: 0,
          INC: 0,
          Numeric: 0, // For numeric values in the 'hora' field
          HO: 0,
          PH: 0,
        };

        result.forEach(item => {
          if (item.hora === 'F') counts.F++;
          if (item.retardo === 'Y') counts.R++;
          if (item.hora === 'V') counts.V++;
          if (item.hora === 'INC') counts.INC++;
          if (/\d+/.test(item.hora)) counts.Numeric++;
          if (item.hora === 'HO') counts.HO++;
          if (item.hora === 'PH') counts.PH++;
        });

        // Prepare data for Tabulator
        const tabulatorData = [
          { tipo: 'Pases de Asistencia', count: counts.Numeric }, 
          { tipo: 'Faltas', count: counts.F},
          { tipo: 'Retardos', count: counts.R},
          { tipo: 'Vacaciones', count: counts.V},
          { tipo: 'Incapacidades', count: counts.INC},
          { tipo: 'Home Office', count: counts.HO},
          { tipo: 'Pago Horas', count: counts.PH}
        ];

        setData(tabulatorData);

        // Prepare data for Pie Chart
        setChartData({
          labels: ['Faltas', 'Retardos', 'Vacaciones', 'Incapacidades', 'Pases de Asistencia', 'Home Office', 'Pago Horas'],
          datasets: [
            {
              label: 'Cantidad',
              data: [counts.F, counts.R, counts.V, counts.INC, counts.Numeric, counts.HO, counts.PH],
              backgroundColor: [
                '#DC3545', // Color for Faltas
                '#FF4500', // Color for Retardos
                '#36A2EB', // Color for Vacaciones
                '#FFCE56', // Color for Incapacidades
                '#22BB33', // Color for Numeric Values (Pases de Asistencia)
                '#A020F0', // Color for Home Office
                '#46BDC6', // Color for Pago Horas
              ],
              borderColor: 'rgba(0, 0, 0, 0.1)',
              borderWidth: 1,
            },
          ],
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Hide loading spinner
      }
    };

    fetchData();
  }, [CLIENTID]); // Make sure to include CLIENTID in the dependency array

  useEffect(() => {
    if (!loading && tableRef.current && data.length > 0) {
      // Initialize Tabulator
      new Tabulator(tableRef.current, {
        data: data,
        layout: 'fitColumns',
        columns: [
          { title: 'Registro', field: 'tipo' },
          { title: 'Incidencias', field: 'count', hozAlign: 'center', width: 75 },
        ],
      });
    }
  }, [loading, data]); // Ensure Tabulator initializes after data and loading state are updated

  return (
    <div>
      {loading ? (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      ) : (
        <div>
          <div ref={tableRef}></div>
          <div className='container'>
            <Doughnut data={chartData} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CountTablaHoy;
