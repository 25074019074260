import { useState, useEffect } from "react";
import { 
    StyledTitle, 
    Avatar, 
    StyledFormArea, 
    colors, 
    StyledContainer,
    StyledFormButton,
} from '../components/Styles';
import Logo from "./../assets/liccoLogo.png";
import axios from "axios";
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import M from 'materialize-css';

export const Auth = () => {
    useEffect(() => {
        // Initialize Materialize CSS
        M.AutoInit();
    }, []);

    return (
        <div> 
            <StyledContainer>
                <StyledFormArea>
                    <Avatar $image={Logo} />
                    <StyledTitle color={colors.theme} size={24}>
                        Iniciar Sesión
                    </StyledTitle>
                    <Loginn />
                </StyledFormArea>
            </StyledContainer>
        </div>
    );
};

const Loginn = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [, setCookies] = useCookies(["access_token"]);
    const navigate = useNavigate();

    const onSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post("https://alb.licco.com.mx/auth/login", {
                username,
                password,
            });

            if (response.data.token) {
                setCookies("access_token", response.data.token);
                window.localStorage.setItem("userID", response.data.userID);
                window.localStorage.setItem("username", response.data.username);
                window.localStorage.setItem("clientId", response.data.clientId);
                M.toast({ html: 'Acceso Correcto', classes: 'green darken-1' });
                navigate("/dashboard");
            } else {
                M.toast({ html: response.data.message, classes: 'red darken-1' });
            }
        } catch (err) {
            console.error(err);
            M.toast({ html: 'Usuario o contraseña incorrecta', classes: 'red darken-1' });
        }
    };

    return (
        <Form
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            onSubmit={onSubmit}
        />
    );
};

const Form = ({ username, setUsername, password, setPassword, onSubmit }) => {
    return (
        <div className="container">
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <label htmlFor="username">Usuario:</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                        required
                        style={{
                            padding: '8px',
                            margin: '5px 0',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            width: '100%',
                        }}
                    /> 
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                        style={{
                            padding: '8px',
                            margin: '5px 0',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            width: '100%',
                        }}
                    /> 
                </div>
                
                <StyledFormButton
                    type="submit"
                >
                    Entrar
                </StyledFormButton>
            </form>
        </div>
    );
};
