import { NavbarButtons } from "./Styles";
//import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { LogOutButtons } from "./Styles";

const Navbar = () =>{

    const [cookies, setCookies] = useCookies(["access_token"])
    const navigate = useNavigate()


 const logout = () => {
    setCookies("access_token", "")
    window.localStorage.removeItem("userID")
    window.localStorage.removeItem("username")
    window.localStorage.removeItem("clientId")
    navigate("/auth")
 }

    return(
        <div style={{
            padding : "20px"
        }}>
            
            <NavbarButtons to="/dashboard" >Inicio</NavbarButtons>
            <NavbarButtons to="/cale" >Calendario</NavbarButtons>
            <NavbarButtons to="/inci" >Incidencias</NavbarButtons>
            <NavbarButtons to="/emps" >Empleados</NavbarButtons>
            <NavbarButtons to="/pref">Formatos</NavbarButtons>
            <div style={{
                position : "absolute",
                top : 20,
                right : 0,
                }}>

          {!cookies.access_token ? (<NavbarButtons to="/auth">salir</NavbarButtons>) : (
            <LogOutButtons onClick={logout}>
            salir
            </LogOutButtons>
          )}
            </div>
            

        </div>
    );
}

export default Navbar